import gql from "graphql-tag";

const GetAllGptPrompts = gql`
  query ($input: GetPromptsDto!) {
    GetAllGptPrompts(getPromptsDto: $input) {
      prompts {
        prompt_key
        prompt
        variables
        prompt_name
        variable_info
      }
      totalCount
    }
  }
`;

const GetGptPrompt = gql`
  query ($input: GetPromptDto!) {
    GetGptPrompt(getPromptDto: $input) {
      prompt {
        prompt_key
        prompt_name
        prompt
        variables
        variable_info
      }
    }
  }
`;

const editGptPrompt = gql`
  mutation ($input: EditPromptDto!) {
    editGptPrompt(editPromptDto: $input) {
      success
      message
    }
  }
`;

const PreviewEditPrompt = gql`
  mutation ($input: PreviewPromptDto!) {
    PreviewEditPrompt(previewPromptDto: $input) {
      openaiResponse
      prompt
    }
  }
`;

export { GetAllGptPrompts, GetGptPrompt, editGptPrompt, PreviewEditPrompt };
