import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetTemplateRequest,
  ReviewTemplate,
} from "../../shared/graphQL/templates/queries";
import Label from "../../shared/components/Label";
import { TextArea } from "../prompts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SuspenseLoader from "../../shared/components/SuspenseLoader";
import { PreviewIcon } from "../../styles/theme/svgicon";
import CustomModal from "../../shared/components/customModal/CustomModal";
import TemplatePreview from "./TemplatePreview";

const schema = Yup.object().shape({
  rejectReason: Yup.string()
    .required("Please enter reason.")
    .matches(
      /^[^\s][\w\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]*$/,
      "Please enter a valid reason"
    )
    .max(256, "Max length exceeded"),
});
const TemplateDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [templateData, setTemplateData] = useState(undefined);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [buttonStatus, setButtonStatus] = useState("");
  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const theme = useTheme();
  const [
    updateTemplateStatus,
    { data: statusUpdatedData, loading: statusUpdateLoading },
  ] = useMutation(ReviewTemplate);
  const [
    getTemplateDetails,
    { data: templateDetailsData, loading: templateDetailLoader, refetch },
  ] = useLazyQuery(GetTemplateRequest);

  useEffect(() => {
    getTemplateDetails({ variables: { input: { templateId: id } } });
  }, []);

  useEffect(() => {
    if (templateDetailsData) {
      console.log(
        "templateDetailsData",
        templateDetailsData.GetTemplateRequest
      );

      setTemplateData(templateDetailsData.GetTemplateRequest);
    }
  }, [templateDetailsData]);

  type Color = "error" | "info" | "secondary" | "warning";

  const getStatusLabel = (
    status: string | "pending" | "rejected" | "approved" | "all"
  ): JSX.Element => {
    let color = "";
    let text = "";
    switch (status) {
      case "pending":
        text = "Pending";
        color = "secondary";
        break;
      case "rejected":
        text = "Rejected";
        color = "error";
        break;
      case "approved":
        text = "Approved";
        color = "info";
        break;
      default:
        text = "All";
        color = "warning";
        break;
    }
    return <Label color={color as Color}>{text}</Label>;
  };
  const changeStatus = (status: string) => {
    setButtonStatus(status);
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const { register, formState, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      rejectReason: "",
    },
  });

  const handleApprove = () => {
    let data = {
      id: templateData?._id,
      status: "approved",
    };
    updateTemplateStatus({
      variables: {
        input: data,
      },
    });
  };

  const onSubmit = async (formFields) => {
    let data = {
      id: templateData?._id,
      status: buttonStatus,
      rejectReason: formFields.rejectReason,
    };

    await updateTemplateStatus({
      variables: {
        input: data,
      },
    });
    if (buttonStatus === "removed") {
      navigate("/templates");
    }
  };

  useEffect(() => {
    if (statusUpdatedData) {
      handleClose();
      refetch();
    }
  }, [statusUpdatedData]);

  const renderSkeleton = () => {
    return (
      <>
        <Paper sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="text" height={50} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" height={50} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="text" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginBottom={1}
          alignItems={"center"}
        >
          <Typography variant="h3" marginBottom={1}>
            Template Details
          </Typography>
          {templateData && (
            <Box sx={{ display: "flex" }}>
              <Button
                sx={{ mr: 1 }}
                variant="outlined"
                size="small"
                onClick={handleOpenPreview}
              >
                <PreviewIcon />
                Preview
              </Button>
              {templateData?.status == "pending" ? (
                <>
                  <Button
                    sx={{ mr: 1 }}
                    variant="outlined"
                    size="small"
                    onClick={() => changeStatus("rejected")}
                    color="secondary"
                  >
                    Reject
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => changeStatus("approved")}
                  >
                    Approve
                  </Button>
                </>
              ) : (
                templateData?.status === "approved" && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={() => changeStatus("removed")}
                  >
                    Remove
                  </Button>
                )
              )}
            </Box>
          )}
        </Box>

        {templateData ? (
          <Paper sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="h4">Template Name</Typography>
                  <Box>
                    <Typography variant="body1">
                      {templateData?.title}
                    </Typography>
                    <img
                      height={"100px"}
                      width={"100px"}
                      src={templateData?.image}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box>
                  <Typography variant="h4">Template Description</Typography>
                  <Typography variant="body1">
                    {templateData?.description}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box>
                  <Typography marginBottom={1} variant="h4">
                    Status
                  </Typography>
                  <Typography variant="body1">
                    {getStatusLabel(templateData?.status)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="h4">User Name</Typography>
                  <Typography variant="body1">
                    {templateData?.user?.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="h4">User Email</Typography>
                  <Typography variant="body1">
                    {templateData?.user?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography variant="h4">Category</Typography>
                  <Typography variant="body1">
                    {templateData?.categories.map(
                      (category, ind) => (ind > 0 ? ", " : "") + category.name
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          renderSkeleton()
        )}
      </Box>

      <Dialog
        open={showDialog}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ overflowX: "hidden" }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            padding: "16px 24px 0px 24px",
            color: theme.colors.alpha.black[100],
            fontFamily: "TomatoGroteskMedium",
          }}
        >
          {buttonStatus === "approved" &&
            "Are you sure you want to approve this template?"}
          {buttonStatus === "rejected" &&
            "Are you sure you want to reject this template?"}
          {buttonStatus === "removed" &&
            "Are you sure you want to remove this template?"}
        </DialogTitle>

        {buttonStatus === "approved" && (
          <DialogActions>
            <Button onClick={handleClose} size="small">
              Back
            </Button>
            <Button
              disabled={statusUpdateLoading}
              onClick={handleApprove}
              variant="contained"
              size="small"
            >
              Approve
            </Button>
          </DialogActions>
        )}

        {(buttonStatus === "removed" || buttonStatus === "rejected") && (
          <Box
            component="form"
            noValidate
            sx={{ width: 550 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <DialogContent>
              <TextArea
                minRows={3}
                {...register("rejectReason")}
                placeholder="Please enter your comment"
              />
              {formState.errors?.rejectReason && (
                <Typography sx={{ color: theme.colors.error.light }}>
                  {formState.errors?.rejectReason?.message}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} size="small">
                Back
              </Button>
              <Button
                disabled={statusUpdateLoading}
                variant="contained"
                type="submit"
                size="small"
              >
                {buttonStatus === "rejected" ? "Reject" : "Remove"}
              </Button>
            </DialogActions>
          </Box>
        )}
        {statusUpdateLoading && <SuspenseLoader left="0%" />}
      </Dialog>

      {openPreview && (
        <CustomModal
          onClose={() => setOpenPreview(false)}
          enableBackgroundClick
        >
          <TemplatePreview
            data={templateData}
            onClose={() => setOpenPreview(false)}
          />
        </CustomModal>
      )}
    </>
  );
};

export default TemplateDetails;
