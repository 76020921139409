import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import SharedTable from "../../shared/components/Table";
import {
  GetAllGptPrompts,
  GetGptPrompt,
  PreviewEditPrompt,
  editGptPrompt,
} from "../../shared/graphQL/prompts/queries";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  TextareaAutosize,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import useStyles from "../../styles/theme/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SuspenseLoader from "../../shared/components/SuspenseLoader";
import ReactMarkdown from "react-markdown";
import DynamicVariableForm from "./DynamicVariableForm";

const columns = [
  { id: "prompt_name", label: "Prompt Name", width: "300px" },
  { id: "variable_info", label: "Variable Info", width: "400px" },
  { id: "prompt", label: "Prompt", width: "400px" },
  { id: "action", label: "Action", width: "100px" },
];
type SelectedRowData = {
  prompt_key: string;
  prompt_name: string;
  prompt: string;
  variables: string;
  variable_info: string;
};

export const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: "TomatoGrotesk",
  fontSize: "14px",
  borderColor: "rgba(0, 0, 0, 0.23)", // Use theme color
  borderRadius: "4px",
  padding: "16.5px 14px",
  lineHeight: 1.7,
  width: "100%",
  "&:focus-visible": {
    borderColor: theme.colors.primary.main, // Change border color on focus
    outline: "none",
  },
}));

const schema = Yup.object().shape({
  prompt_name: Yup.string()
    .required("Please enter prompt name.")
    .matches(
      /^[^\s][\w\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]*$/,
      "Please enter a valid prompt name."
    )
    .max(50, "Max length exceeded"),
  prompt: Yup.string().required("Please enter prompt."),
});

const PromptList = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [promptsList, setPromptsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openPromptDialog, setOpenPromptDialog] = useState(false);
  const [openPromptPreviewDialog, setOpenPromptPreviewDialog] = useState(false);
  const [selectedRowData, setselectedRowData] = useState<SelectedRowData>({
    prompt_key: "",
    prompt_name: "",
    prompt: "",
    variables: "",
    variable_info: "",
  });
  const [dynamicVariables, setDynamicVariables] = useState([]);
  const [previewResposeOpenAi, setPreviewResposeOpenAi] = useState(undefined);

  const [getPrompts, { data: getPromptsList, loading: promptLoader, refetch }] =
    useLazyQuery(GetAllGptPrompts);
  const [
    getPromptDetails,
    { data: getPromptInfo, loading: promptDetailLoader },
  ] = useLazyQuery(GetGptPrompt);
  const [updatePrompt, { data: updatePrompts, loading: updatePromptLoader }] =
    useMutation(editGptPrompt);
  const [
    previewPromptApi,
    { data: previewPromptData, loading: previewPromptLoader },
  ] = useMutation(PreviewEditPrompt);

  const { register, handleSubmit, formState, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      prompt_name: "",
      prompt: "",
    },
  });

  const createValidationSchema = (dynamicVariables) => {
    const schema = {};
    dynamicVariables.forEach((variable) => {
      schema[variable] = Yup.string().required(`Please enter ${variable}`);
    });
    return Yup.object().shape(schema);
  };

  const {
    register: registerDynamicForm,
    handleSubmit: handleDynamicForm,
    formState: dynamicFormState,
    reset: dynamicFormvalueReset,
  } = useForm({
    resolver: yupResolver(createValidationSchema(dynamicVariables)),
  });

  useEffect(() => {
    getPrompts({
      variables: {
        input: {
          page: page + 1,
          limit: rowsPerPage,
        },
      },
    });
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (getPromptsList) {
      setPromptsList(getPromptsList.GetAllGptPrompts.prompts);
      setTotalCount(getPromptsList.GetAllGptPrompts.totalCount);
    }
  }, [getPromptsList]);

  useEffect(() => {
    if (getPromptInfo) {
      const formData = {
        prompt_name: getPromptInfo.GetGptPrompt.prompt.prompt_name,
        prompt: getPromptInfo.GetGptPrompt.prompt.prompt,
      };
      setDynamicVariables(
        getPromptInfo.GetGptPrompt.prompt.variables.split(/,\s*/)
      );
      reset(formData);
    }
  }, [getPromptInfo]);

  useEffect(() => {
    if (updatePrompts) {
      refetch();
    }
  }, [updatePrompts]);

  useEffect(() => {
    if (previewPromptData) {
      setPreviewResposeOpenAi(previewPromptData.PreviewEditPrompt);
    }
  }, [previewPromptData]);

  const formatPromptsList = promptsList.map((row) => {
    return {
      prompt_key: (
        <>
          <strong>{row.prompt_key}</strong>
        </>
      ),
      prompt_name: (
        <>
          <strong>{row.prompt_name}</strong>
        </>
      ),
      variable_info: (
        <>
          <ReactMarkdown children={row.variable_info} />
        </>
      ),
      prompt: (
        <>
          <Box className={classes.hideText}>
            <strong>
              <div>{row.prompt}</div>
            </strong>
          </Box>
        </>
      ),

      action: (
        <>
          <IconButton
            sx={{
              "&:hover": {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
            onClick={() => handleEditClick(row)}
          >
            <EditTwoToneIcon fontSize="small" sx={{ color: "#0481D9" }} />
          </IconButton>
        </>
      ),
    };
  });

  const handleEditClick = async (row) => {
    await getPromptDetails({
      variables: {
        input: {
          prompt_key: row.prompt_key,
        },
      },
    });
    setselectedRowData(row);
    setOpenPromptDialog(true);
  };

  const handleClose = () => {
    setOpenPromptDialog(false);
    setPreviewResposeOpenAi(undefined);
    dynamicFormvalueReset();
  };

  const formSubmit = async (data) => {
    if (data.prompt.trim()) {
      const promptData = {
        prompt_key: selectedRowData.prompt_key,
        variables: selectedRowData.variables,
        variable_info: selectedRowData.variable_info,
        prompt_name: data.prompt_name,
        prompt: stringToBase64(data.prompt),
      };
      await updatePrompt({ variables: { input: promptData } });
      handleClose();
    }
  };

  // Function to convert a string to base64
  const stringToBase64 = (input: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const base64 = btoa(String.fromCharCode(...data));
    return base64;
  };

  const handleRowsPerPageChange = (rowperpage) => {
    setRowsPerPage(rowperpage);
    setPage(0); // Reset page when changing rows per page
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePreviewClose = () => {
    setOpenPromptPreviewDialog(false);
    setOpenPromptDialog(true);
  };

  const showPreviewDialog = () => {
    setOpenPromptDialog(false);
    setOpenPromptPreviewDialog(true);
  };

  function objectToKeyValuePairs(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keyValuePairs.push({ key, value: obj[key] });
      }
    }
    return keyValuePairs;
  }

  const dynamicFormSubmit = async (data) => {
    const formValues = getValues();
    let payload = {
      variablesValues: objectToKeyValuePairs(data),
      prompt: stringToBase64(formValues.prompt),
    };
    await previewPromptApi({ variables: { input: payload } });
  };

  return (
    <Container component="main">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ ms: 2, mt: 2 }}
      >
        <Grid item>
          <Typography sx={{ mb: 2 }} variant="h3" component="h3" gutterBottom>
            List of Prompts
          </Typography>
        </Grid>
      </Grid>
      <SharedTable
        columns={columns}
        data={formatPromptsList}
        page={page}
        tableBodyLoader={promptLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        searchFilterVisible={false}
        onSearch={undefined}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>

      <Dialog
        open={openPromptDialog}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ overflowX: "hidden" }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            padding: "16px 24px 0px 24px",
            color: theme.colors.alpha.black[100],
            fontFamily: "TomatoGroteskMedium",
          }}
        >
          Update prompt
        </DialogTitle>
        <Box
          component="form"
          onSubmit={handleSubmit(formSubmit)}
          noValidate
          sx={{ width: 550 }}
        >
          <DialogContent>
            <Box
              sx={{
                maxHeight: "calc(100vh - 250px)",
                overflowY: "auto",
                maxWidth: "100%",
              }}
            >
              <div>
                <TextField
                  {...register("prompt_name")}
                  label="Prompt Name"
                  margin="normal"
                  fullWidth
                />
                {formState.errors?.prompt_name && (
                  <Typography sx={{ color: theme.colors.error.light }}>
                    {formState.errors?.prompt_name?.message}
                  </Typography>
                )}
              </div>
              <Typography sx={{ mb: 1 }}></Typography>
              <div>
                <Typography
                  sx={{
                    mb: 1,
                    color: theme.colors.alpha.black[100],
                    fontFamily: "TomatoGroteskMedium",
                  }}
                >
                  Prompt
                </Typography>
                <TextArea
                  minRows={10}
                  {...register("prompt")}
                  placeholder="Enter your prompt"
                />
                {formState.errors?.prompt && (
                  <Typography sx={{ color: theme.colors.error.light }}>
                    {formState.errors?.prompt?.message}
                  </Typography>
                )}
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={showPreviewDialog}
              variant="contained"
              color="success"
            >
              Preview
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={updatePromptLoader}
            >
              Update
            </Button>
          </DialogActions>
        </Box>
        {updatePromptLoader && <SuspenseLoader left="0%" />}
      </Dialog>

      <Dialog
        open={openPromptPreviewDialog}
        onClose={handlePreviewClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ overflowX: "hidden" }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            padding: "16px 24px 0px 24px",
            color: theme.colors.alpha.black[100],
            fontFamily: "TomatoGroteskMedium",
          }}
        >
          Preview
        </DialogTitle>
        <Box
          component="form"
          onSubmit={handleDynamicForm(dynamicFormSubmit)}
          noValidate
          sx={{ width: 550 }}
        >
          <DialogContent>
            <Box
              sx={{
                maxHeight: "calc(100vh - 250px)",
                overflowY: "auto",
                maxWidth: "100%",
              }}
            >
              <DynamicVariableForm
                register={registerDynamicForm}
                dynamicVariables={dynamicVariables}
                formState={dynamicFormState}
              />

              {previewResposeOpenAi?.prompt && (
                <code className={classes.codeFormat}>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="h3"
                    component="h3"
                    gutterBottom
                  >
                    Generated prompt preview :
                  </Typography>
                  {previewResposeOpenAi?.prompt}
                </code>
              )}
              {previewResposeOpenAi?.openaiResponse && (
                <code className={classes.codeFormat}>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="h3"
                    component="h3"
                    gutterBottom
                  >
                    Response from OpenAI :
                  </Typography>
                  {previewResposeOpenAi?.openaiResponse}
                </code>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePreviewClose}>Back</Button>
            <Button
              variant="contained"
              type="submit"
              disabled={previewPromptLoader}
            >
              Get Response
            </Button>
          </DialogActions>
        </Box>
        {previewPromptLoader && <SuspenseLoader left="0%" />}
      </Dialog>
      {promptDetailLoader && <SuspenseLoader left="0%" />}
    </Container>
  );
};

export default PromptList;
