import gql from "graphql-tag";

const GetTemplatesRequests = gql`
  query GetTemplatesRequests($input1: PageDto!, $input2: Filter!) {
    GetTemplatesRequests(pageDto: $input1, filter: $input2) {
      templates {
        _id
        title
        image
        description
        stages {
          name
          suggestions
        }
        categories {
          _id
          name
          templates_count
          imageUrl
          description
        }
        user {
          _id
          email
          name
          profile_picture
          status
        }
        is_public
        status
        admin_feedback
      }
      totalCount
    }
  }
`;

const ReviewTemplate = gql`
  mutation ($input: ReviewTemplateDto!) {
    ReviewTemplate(reviewTemplateDto: $input) {
      success
      message
    }
  }
`;

const GetTemplateRequest = gql`
  query ($input: GetTemplateRequestDto!) {
    GetTemplateRequest(getTemplateRequestDto: $input) {
      _id
      title
      image
      description
      stages {
        name
        suggestions
      }
      categories {
        _id
        name
        templates_count
        imageUrl
        description
      }
      user {
        _id
        email
        name
        profile_picture
        status
      }
      is_public
      status
      admin_feedback
    }
  }
`;

export { GetTemplatesRequests, ReviewTemplate, GetTemplateRequest };
